<!--
 * @Author: Neko
 * @Date: 2021-01-07 09:27:13
 * @LastEditTime: 2021-03-26 10:06:59
 * @LastEditors: Neko
-->
<template>
  <div class="profile__container">
    <div class="profile__wrap-list">
      <div class="container__header">
        <div class="container__wrap">
          <h2 class="title">{{ $t('me.account_center') }}</h2>
        </div>
      </div>
      <div class="profile__main">
        <div class="profile__main-wrap">
          <div class="list">
            <div class="profile__wrap">
              <div class="profile__header">
                <div class="avatar">
                  <div class="img">
                    <img src="@/assets/images/profile/avatar.png">
                  </div>
                </div>

                <h3 class="name">{{ nickname }}</h3>
              </div>

              <div class="profile__body">
                <ul class="feature">
                  <li v-for="item in profileList" :key="item.name" class="item" @click="onItemClick(item)">
                    <router-link :to="{ name: item.url }" class="link" :target="item.isBlank ? '_blank' : ''">
                      <!-- <img class="icon" :src="item.icon"> -->

                      <span class="feature-name">{{ item.name }}</span>

                      <!-- <i class="arrow el-icon-arrow-right" /> -->
                    </router-link>
                  </li>
                </ul>

                <div class="button-wrap">
                  <el-button class="button" @click="onLogout">{{ $t('me.sign_out') }}</el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <h2 class="title">{{ routeName }}</h2>
            <router-view />
          </div>
          <AuthDialog :visible="isShowAuthDialog" @success="onAuthSuccess" @close="onCloseAuth" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import useChangeBackground from '@/composition/useChangeBackground.js'
import { computed, reactive, toRefs, ref } from '@vue/composition-api'
import AuthDialog from '@/components/Common/AuthDialog.vue'
import { useRequest } from '@/utils/request.js'

export default {
  name: 'ProfileView',

  components: {
    AuthDialog
  },
  setup(_, { root }) {
    useChangeBackground('#F6F6F6')

    const nickname = ref('')

    const { $store, $router, $jst } = root

    nickname.value = $store.state.user.userData.username

    const routeName = computed(() => {
      return $jst('order.myOrder') // $route.meta?.name
    })

    const data = reactive({
      profileList: computed(() => ([
        // {
        //   icon: require('@/assets/images/profile/collection.png'),
        //   name: '我的收藏',
        //   url: 'Collection',
        //   isBlank: true
        // },
        {
          icon: require('@/assets/images/profile/address.png'),
          name: $jst('me.delivery_address'),
          url: 'Address',
          isBlank: true
        },
        {
          icon: require('@/assets/images/profile/address.png'),
          name: $jst('me.account_setting'),
          url: 'Account',
          isBlank: true
        }
        // {
        //   icon: require('@/assets/images/profile/setting.png'),
        //   name: '实名认证',
        //   url: ''
        // }
        // {
        //   icon: require('@/assets/images/profile/wallet.png'),
        //   name: '我的钱包',
        //   url: ''
        // },
        // {
        //   icon: require('@/assets/images/profile/card.png'),
        //   name: '我的银行卡',
        //   url: ''
        // },

      ])),
      isShowAuthDialog: false
    })

    useRequest('user/userInfo', {
      isCustom: true,
      onSuccess: (res) => {
        const { data } = res
        $store.dispatch('user/GetUserInfo', data)
      }
    })

    const onItemClick = (item) => {
      const { name } = item
      if (name === '实名认证') {
        data.isShowAuthDialog = true
      }
    }

    const onCloseAuth = () => {
      data.isShowAuthDialog = false
    }

    const onAuthSuccess = () => {
      data.isShowAuthDialog = false
    }

    const onLogout = () => {
      $store.dispatch('user/Logout')
      $router.push({ path: '/user/login' })
    }

    return {
      routeName,
      ...toRefs(data),
      nickname,
      onLogout,
      onItemClick,
      onCloseAuth,
      onAuthSuccess
    }
  }
}
</script>

<style lang="scss" scoped>
.profile__container {
  // margin: auto;
  overflow: hidden;

  .profile__wrap-list {
    max-width: 1600px;
    width: 96.9%;
    min-width: 1200px;
    border-radius: 8px 8px 0px 0px;
    margin: 0 auto 100px;
  }

  .container__header {
    position: relative;
    z-index: 2;
    // padding: 0 200px;
    width: 100%;
    height: 80px;
    // margin: 0 auto;
    // box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);
    border-radius: 8px 8px 0px 0px;
    background: #fff;

    .container__wrap {
      width: 1200px;
      margin: 0 auto;
    }

    .title {
      display: inline-block;
      color: #0049AC;
      font-size: 18px;
      font-weight: bold;
      height: 80px;
      line-height: 80px;
      border-bottom: 2px solid #0049AC;
      box-sizing: border-box;
    }
  }
}

.profile__main {
  position: relative;
  z-index: 1;
  box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);

  .profile__main-wrap {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    padding: 20px 0 81px;
    margin: 0 auto 0;
    // margin: 47px auto 96px;

  }

  .list {
    width: 280px;
    border-radius: 8px;
  }

  .view {
    display: flex;
    flex-direction: column;
    width: 880px;
    background: #FFFFFF;
    box-shadow: 0px 6px 12px 0px #E8E8EA;
    border-radius: 8px;
  }

  .title {
    height: 80px;
    // line-height: 80px;
    border-bottom: 1px solid #F2F2F2;
    padding: 41px 0 20px 38px;
    font-size: 18px;
    font-weight: 400;
  }
}

.profile__wrap {
  // padding: 53px 23px 30px 28px;
  padding: 60px 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 91px 89px;
  box-shadow: 0px 17px 102px 15px rgb(232 232 234 / 95%);
  background: linear-gradient(-45deg, #E2EBF7 0%, #F5F8FB 100%);
  // box-shadow: 0px 6px 12px 0px #E8E8EA;

  .profile__header {
    margin-bottom: 32px;
  }

  .avatar {
    .img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 0 auto;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .name {
    font-weight: bold;
    padding: 12px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #222;
  }
}

.profile__body {
  .feature {
    .item {
      &:last-child {
        .link {
          margin-bottom: 0;
        }

      }

      .link {
        display: flex;
        align-items: center;
        margin-bottom: 21px;

        .icon {
          width: 39px;
          height: 39px;
        }

        .feature-name {
          width: 100%;
          // margin-left: 16px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #222;
        }

        .arrow {
          font-weight: bolder;
          color: #c2c2c2;
        }
      }

    }
  }

  .button-wrap {
    margin-top: 36px;
    text-align: center;

    .button {
      width: 200px;
      height: 50px;
      border: 1px solid #0049AC;
      border-radius: 25px;
      background: transparent;
    }
  }
}
</style>
